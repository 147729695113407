<template>
  <me-store-skeleton v-if="in_request" />
  <div v-else-if="getDashImage.length" class="bg-white full-height">
    <template v-for="(item, i) in getDashImage" :key="i">
      <q-img
        :name="i + 1"
        :src="item.value"
        v-if="i + 1 == slide"
        @click="windowOpen(item)"
        style="width: 100%; object-fit: cover; cursor: pointer"
      />
    </template>
    <q-btn
      v-if="getDashImage.length > 1"
      @click="prevSlide"
      icon="arrow_left"
      class="slide-button left"
      round
      dense
    />
    <q-btn
      v-if="getDashImage.length > 1"
      @click="nextSlide"
      icon="arrow_right"
      class="slide-button right"
      round
      dense
    />
  </div>
  <router-link v-else :to="{ name: 'me.store' }">
    <div class="bg-white self-center row items-center full-height">
      <q-img
        :src="$q.screen.width > 900 ? storeNotFound : storeNotFoundMobile"
      />
    </div>
  </router-link>
</template>

<script setup>
import meStoreSkeleton from "../skeleton/myStoreSkeleton.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted, ref } from "vue";
import { useQuasar } from "quasar";

const { useGetters, useActions } = createNamespacedHelpers("home"),
  UseAssents = createNamespacedHelpers("assents");

const slide = ref(1);
const in_request = ref(false),
  $q = useQuasar();

const { fetchDashImage } = useActions(["fetchDashImage"]),
  { getDashImage } = useGetters(["getDashImage"]),
  { storeNotFound, storeNotFoundMobile } = UseAssents.useGetters([
    "storeNotFound",
    "storeNotFoundMobile",
  ]);

const windowOpen = (item) => {
  if (item.link_to_open_target)
    window.open(item.link_to_open, item.link_to_open_target);
  return;
};

const prevSlide = () => {
  slide.value = slide.value > 1 ? slide.value - 1 : getDashImage.value.length;
};

const nextSlide = () => {
  slide.value = slide.value < getDashImage.value.length ? slide.value + 1 : 1;
};

onMounted(() => {
  in_request.value = true;
  fetchDashImage({
    target: $q.platform.is.mobile
      ? "dashboard_image_center_mobile"
      : "dashboard_image_center",
  }).finally(() => (in_request.value = false));
});
</script>

<style>
.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.slide-button.left {
  left: 10px;
}
.slide-button.right {
  right: 10px;
}
</style>
