<template>
  <div v-if="hasOnlyCoupon">
    <div
      class="row container align-items-center"
      :class="{
        'container-opened': openedOrClosed,
        'container-closed': !openedOrClosed,
      }"
    >
      <div
        class="col-12"
        v-if="isCopyActionsEnabled"
        :class="{
          'col-md-7': $q.screen.width > 1400,
          'col-md-8': $q.screen.width <= 1400,
          'q-mb-md': $q.screen.width <= 800,
        }"
      >
        <span v-if="$q.screen.width <= 800">Copie e Compartilhe:</span>
        <div
          :class="{
            'q-mt-sm': $q.screen.width <= 800,
          }"
        >
          <span v-if="$q.screen.width > 800" class="q-mr-sm"
            >Copie e Compartilhe:</span
          >
          <CopyActions :displaysClusteredParts="displaysClusteredParts" />
        </div>
      </div>
      <div
        class="col-12 text-right q-mt-xs row"
        :class="{
          'col-md-5': $q.screen.width > 1400 && isCopyActionsEnabled,
          'col-md-4 width-fit q-ml-auto':
            $q.screen.width <= 1400 && isCopyActionsEnabled,
        }"
        @click="toggleOpen"
      >
        <div v-if="displaysClusteredParts?.show_level && clusterLevel">
          Você está no nível:
          <q-img
            class="cluster-container-img"
            :src="getCluster.icon_asset_or_url"
          />
          <b v-text="clusterLevel" />
        </div>

        <q-icon
          v-if="displaysClusteredParts?.show_level_detail && hasClusterInfoData"
          :name="'keyboard_arrow_' + (openedOrClosed ? 'up' : 'down')"
          class="arrow q-ml-md"
        />
      </div>
    </div>
    <div
      class="row dropdown-container align-items-center"
      v-if="openedOrClosed"
    >
      <div class="q-mb-md" v-if="$q.screen.width <= 768">
        <b>Realize vendas para atingir o próximo nível</b>
      </div>
      <div
        class="row col-12"
        :class="{
          'q-col-gutter-md': $q.screen.width > 768,
        }"
      >
        <div class="col-12 col-md-2 text-center">
          <ClusterItemComponent
            :icon="getClusterAndShareData.current_cluster_icon"
            :label="getClusterAndShareData.current_cluster_label"
            :explain="getClusterAndShareData.current_cluster_explain"
          />
        </div>
        <div
          class="col progress-font-size"
          :class="{
            'q-mt-lg q-pr-md q-pl-md': $q.screen.width > 768,
            'q-mt-md q-pr-xs q-pl-xs': $q.screen.width <= 768,
          }"
        >
          <div class="row">
            <div v-if="$q.screen.width > 768">
              <b>Realize vendas</b> para atingir o próximo nível
            </div>
            <div class="q-ml-auto" v-html="getClusterAndShareData.message_1" />
          </div>
          <q-linear-progress
            size="20px"
            color="grey"
            class="q-mt-sm default-rounded"
            :value="getClusterAndShareData.cluster_reaching"
          />
          <div class="row q-mt-md" v-if="$q.screen.width > 768">
            <div v-if="$q.screen.width > 1140">
              <b class="know-more-link" @click="openDialog = true">
                Conheça mais sobre os níveis
              </b>
            </div>
            <div class="q-ml-auto" v-html="getClusterAndShareData.message_2" />
          </div>
        </div>
        <div
          class="col-12 col-md-2 text-center"
          :class="{
            'q-mt-md': $q.screen.width <= 768,
          }"
        >
          <ClusterItemComponent
            :type="'next'"
            :icon="getClusterAndShareData.next_cluster_icon"
            :label="getClusterAndShareData.next_cluster_label"
            :explain="getClusterAndShareData.next_cluster_explain"
          />
        </div>
      </div>
      <div v-if="$q.screen.width <= 1140">
        <div
          style="font-size: 9.5pt"
          v-if="$q.screen.width <= 768"
          class="text-justify q-mt-lg"
          v-html="getClusterAndShareData.message_2"
        />

        <div class="q-mt-lg">
          <b class="know-more-link" @click="openDialog = true">
            Conheça mais sobre os níveis
          </b>
        </div>
      </div>
    </div>
    <ClusterPartnerInfoDialog
      v-model="openDialog"
      :rules="getClusterAndShareData.cluster_bullets_rules"
    />
  </div>
</template>

<script setup>
import { useGetters, useActions } from "vuex-composition-helpers";
import ClusterPartnerInfoDialog from "./ClusterPartnerInfoDialog.vue";
import ClusterItemComponent from "./ClusterItemComponent.vue";
import CopyActions from "./CopyActions.vue";
import { computed, ref, watch } from "vue";

const { params, getCluster, getClusterAndShareData, getVoucher } = useGetters({
  params: "account/getParams",
  getCluster: "auth/getCluster",
  getClusterAndShareData: "home/getClusterAndShareData",
  getVoucher: "home/getVoucher",
});

const { fetchClusterAndShare, fetchClusterPartnerInfo } = useActions({
  fetchClusterAndShare: "home/fetchClusterAndShare",
  fetchClusterPartnerInfo: "home/fetchClusterPartnerInfo",
});

const openDialog = ref(false);
const openedOrClosed = ref(false);
const displaysClusteredParts = ref(null);
const hasClusterInfoData = ref(false);

const clusterLevel = computed(() => getCluster.value?.description);

/**
 * Verifica se apenas o cluster e o cupom devem ser exibido.
 */
const isExclusiveClusterAndCouponEnabled = computed(() => {
  const cluster = displaysClusteredParts.value;

  return (
    cluster?.show_bar &&
    cluster?.show_cupom &&
    !cluster?.show_store &&
    !cluster?.show_level_detail &&
    !cluster?.show_level
  );
});

/**
 * Se o cluster e o cupom estiverem habilitados, verifica se há um número de voucher disponível.
 * caso não tenha um voucher disponivel, nao exibe o Cluster
 *
 */
const hasOnlyCoupon = computed(() => {
  if (!isExclusiveClusterAndCouponEnabled.value) return true;

  return isExclusiveClusterAndCouponEnabled.value && getVoucher.value?.number;
});

/**
 * Determina se as ações de cópia e loja devem ser habilitadas.
 *
 * Esta propriedade verifica se `show_cupom` ou `show_store`
 * estão ativados dentro de `displaysClusteredParts`.
 *
 * @returns {boolean} Retorna `true` se pelo menos uma das opções
 * (`show_cupom` ou `show_store`) estiver ativada; caso contrário, retorna `false`.
 */
const isCopyActionsEnabled = computed(
  () =>
    displaysClusteredParts.value?.show_cupom ||
    displaysClusteredParts.value?.show_store
);

watch(
  params,
  (newValue) => {
    displaysClusteredParts.value = newValue?.show_dash_bar?.value;

    // Chama os endpoints apenas quando show_level_detail está habilitado
    if (
      displaysClusteredParts.value?.show_bar &&
      displaysClusteredParts.value?.show_level_detail
    ) {
      fetchClusterPartnerInfo().then(() => (hasClusterInfoData.value = true));
      fetchClusterAndShare();
    }
  },
  { deep: true, immediate: true }
);

//METHODS
const toggleOpen = () => {
  if (displaysClusteredParts.value?.show_level_detail) {
    openedOrClosed.value = !openedOrClosed.value;
  }
};
</script>

<style scoped lang="scss">
$white: #ffffff;
$black: #1d1e2c;

.container {
  background-color: $white;
  color: $black;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.container-opened {
  border-radius: 10px 10px 0 0;
}

.container-closed {
  border-radius: 10px;
}

.dropdown-container {
  background-color: $white;
  color: #000;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #e0e0e0;
}

.arrow {
  font-size: 30px;
  color: $black;
  margin-right: auto;
}

.width-fit {
  width: fit-content !important;
}

.badge-radius {
  border-radius: 15px;
}

.know-more-link {
  cursor: pointer;
  border-bottom: 1px solid $black;
}

.know-more-link:hover {
  color: rgb(92, 92, 92);
  border-bottom: 1px solid rgb(92, 92, 92);
}

.cluster-container-img {
  width: 30px;
  height: 30px;
  padding: 10px;
}

@media (max-width: 767px) {
  .arrow {
    justify-content: center;
  }

  .dropdown-container {
    padding: 20px 20px;
  }
}

@media (min-width: 768px) {
  .container > div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .arrow {
    justify-content: flex-end;
    margin-right: 0%;
  }

  .dropdown-container {
    padding: 10px 30px 20px 30px;
  }
}
</style>
