<template>
  <q-card flat class="default-rounded card-border" style="min-height: 240px">
    <div class="q-pr-md q-pl-md text-center">
      <img
        :src="data.logo_foto_link"
        :class="{
          'q-mt-xl': !isMobile,
          'q-mt-md': isMobile,
        }"
        style="height: 4.5em"
      />
      <q-item-label lines="3" class="text-justify q-mt-lg q-mb-sm">
        <div
          class="text-uppercase text-weight-bolder text-center"
          style="font-size: 14pt"
        >
          {{ data.nome }}
        </div>
      </q-item-label>
    </div>
  </q-card>
  <div class="q-pa-sm" style="margin-top: -65px">
    <q-card-actions class="row q-col-gutter-sm" v-if="!isMobile">
      <div class="col-6" v-if="hasLinkStore()">
        <q-btn
          color="blue-8"
          v-close-popup
          icon="store"
          :label="$t('Ver Loja')"
          class="full-width"
          @click="redirectToStore"
        />
      </div>
      <div
        class="col-6"
        :class="{
          'col-12': !hasLinkStore(),
          'col-6': hasLinkStore(),
        }"
      >
        <ModalFlipPartnerSubscribe
          :data="data"
          :show="true"
          :label="'Inscrever-se'"
        />
      </div>
    </q-card-actions>
    <q-card-actions
      class="row q-col-gutter-sm"
      style="margin-top: -50px"
      v-else
    >
      <div class="col-12" v-if="hasLinkStore()">
        <q-btn
          color="blue-8"
          v-close-popup
          icon="store"
          :label="$t('Ver Loja')"
          class="full-width"
          @click="redirectToStore"
        />
      </div>
      <div class="col-12">
        <ModalFlipPartnerSubscribe
          :data="data"
          :show="true"
          :label="'Inscrever-se'"
        />
      </div>
    </q-card-actions>
  </div>
</template>

<script setup>
import ModalFlipPartnerSubscribe from "./ModalFlipPartnerSubscribe.vue";
import { defineProps } from "vue";
import { useQuasar } from "quasar";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const $q = useQuasar();
const isMobile = $q.screen.width < 700;

const hasLinkStore = () => {
  if (!props.data.parameters) return false;
  return props.data.parameters.find((p) => p.varp == "link_store");
};

const redirectToStore = () => {
  const param = hasLinkStore(),
    link = param.value.split("?")[0];

  window.open(link, "_blank");
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 1px solid #5a5a5a;
}
</style>
