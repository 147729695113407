<template>
  <div v-if="initialized">
    <div>
      <div class="q-mb-lg">
        <ClustersAndShareComponent
          v-if="showCluster && oneOrMoreEnabledPartiesCluster"
        />
      </div>

      <pending-docs />

      <carouselComponent />

      <widgets-container class="q-mt-lg default-card-border" />

      <div v-if="dashboard_mode === 'commission'">
        <div class="row dash-grid full-height q-mt-lg q-col-gutter-sm">
          <div class="col-8">
            <my-store class="full-height default-card-border" />
          </div>
          <div class="col-4">
            <voucher class="full-height default-card-border" />
          </div>
        </div>
        <div class="row dash-grid q-mt-lg q-col-gutter-sm">
          <div class="col-8">
            <products class="full-height default-card-border" />
          </div>
          <div class="col-4">
            <invite class="full-height default-card-border" />
          </div>
        </div>
        <div class="row dash-grid q-mt-lg q-col-gutter-sm">
          <div :class="{ 'col-8': hasSpread, 'col-12': !hasSpread }">
            <graph class="full-height default-card-border" />
          </div>
          <div class="col-4" v-if="hasSpread">
            <share-now class="full-height default-card-border" />
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row dash-grid full-height q-mt-lg q-col-gutter-sm">
          <div class="col-8">
            <my-store class="full-height default-card-border" />
          </div>
          <div class="col-4">
            <voucher class="full-height default-card-border" />
          </div>
        </div>
        <div class="row dash-grid full-height q-mt-lg q-col-gutter-sm">
          <div class="col-8">
            <last-points-released />
          </div>
          <div class="col-4">
            <invite class="full-height default-card-border" />
          </div>
        </div>
      </div>

      <show-up
        v-if="partner.slug != 'insiderstore' && partner.slug != 'maccosmetics'"
      />
      <!---
      <checklist-banner />
    -->

      <div class="row dash-grid q-col-gutter-sm q-mt-lg" id="dash-last-row">
        <div class="col-8">
          <checklists />
        </div>
        <div class="col-4 hidden">
          <drive />
        </div>
        <div class="col-4">
          <visitors-box-component />
        </div>
      </div>
    </div>
  </div>
  <firstAcessTutorial />
</template>

<script setup>
import ClustersAndShareComponent from "../components/ClusterAndShare/ClustersAndShareComponent.vue";
import { getSwitchCompanies } from "@/shared/helpers/switchCompaniesHelper";
import visitorsBoxComponent from "../components/visitorsBoxComponent";
// import ChecklistBanner from "../../../components/checklistBanner";
import firstAcessTutorial from "../components/firstAcessTutorial";
import LastPointsReleased from "../components/lastPointsReleased";
import carouselComponent from "../components/carouselComponent";
import WidgetsContainer from "../components/widgetsContainer";
import Checklists from "../../../components/checklists";
import PendingDocs from "../components/pending-docs";
import drive from "../components/drive.vue";

import { useActions, useGetters } from "vuex-composition-helpers";
import { onMounted, ref, watch } from "vue";

import Products from "../components/products";
import ShareNow from "../components/shareNow";
import MyStore from "../components/myStore";
import Voucher from "../components/voucher";
import Invite from "../components/invite";
import ShowUp from "../components/showUp";
import Graph from "../components/graph";

const initialized = ref(false);
const showCluster = ref(undefined);
const oneOrMoreEnabledPartiesCluster = ref(false);

const { params, hasSpread, dashboard_mode, partner } = useGetters({
  params: "account/getParams",
  hasSpread: "home/hasSpread",
  dashboard_mode: "home/getDashboardMode",
  partner: "partner/getPartner",
});

const { fetchSpread, fetchRewardLayout, fetchParametersByKeys, fetchOverview } =
  useActions({
    fetchSpread: "home/fetchSpread",
    fetchRewardLayout: "home/fetchRewardLayout",
    fetchParametersByKeys: "account/fetchParametersByKeys",
    fetchOverview: "home/fetchOverview",
  });

watch(
  () => params.value,
  (newValue) => {
    const cluster = newValue?.show_dash_bar?.value;
    showCluster.value = cluster?.show_bar;
    oneOrMoreEnabledPartiesCluster.value =
      cluster?.show_cupom ||
      cluster?.show_store ||
      cluster?.show_level ||
      cluster?.show_level_detail;
  }
);

onMounted(() => {
  fetchSpread();
  fetchOverview({
    ["filter[fields]"]:
      "vouchers,linkStores" +
      (getSwitchCompanies() ? ",listCompaniesPartnerRegistered" : ""),
  });
  fetchRewardLayout().finally(() => (initialized.value = true));
  fetchParametersByKeys({
    keys: ["show_dash_bar", "dash_widgets"],
  });
});
</script>

<style lang="scss">
.mobile {
  .dash-grid {
    [class*="col-"] {
      width: 100%;
    }
  }
}
</style>
