<template>
  <q-dialog transition-show="rotate" transition-hide="rotate">
    <q-card class="q-pa-lg" style="width: 1280px; max-width: 90vw">
      <q-bar class="bg-white">
        <p class="q-mt-lg text-uppercase text-weight-bold text-grey">
          Conheça os níveis
        </p>
        <q-space />
        <q-btn color="black" dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-black text-white">{{
            $t("global.close")
          }}</q-tooltip>
        </q-btn>
      </q-bar>
      <hr class="q-mb-lg q-mt-lg q-mr-lg q-ml-lg" />

      <div
        class="text-white know-rules-side"
        :class="{
          'q-pa-sm': $q.screen.width < 800,
          'q-pa-md q-ml-md q-mr-md': $q.screen.width >= 800,
        }"
        style="cursor: pointer"
        @click="openedOrClosed = !openedOrClosed"
      >
        <b class="text-uppercase know-rules">Conheça as regras </b>
        <q-icon
          style="font-size: 15pt; margin-top: -2px"
          :name="'keyboard_arrow_' + (openedOrClosed ? 'up' : 'down')"
          class="arrow q-mr-auto q-ml-xs"
        />
      </div>
      <div
        v-if="openedOrClosed"
        class=""
        :class="{
          'q-pa-xs': $q.screen.width < 800,
          'q-pr-md q-pl-md': $q.screen.width >= 800,
        }"
      >
        <div
          v-html="rules"
          :class="{
            'mobile-list': $q.screen.width <= 800,
          }"
        />
        <hr class="q-mb-lg q-mt-md q-mr-md q-ml-md" />
      </div>

      <div class="row q-mt-lg">
        <div
          class="col-12 col-md-4"
          v-for="(cluster, i) in getClusterPartnerInfo"
          :key="i"
        >
          <q-card
            flat
            class="full-height"
            :class="{
              'q-pa-sm q-pt-lg': $q.screen.width <= 800,
              'q-pa-md': $q.screen.width > 800,
            }"
          >
            <q-bar class="bg-white" style="margin-inline: -10px">
              <q-badge
                color="pink-2"
                style="border-radius: 20px"
                class="q-pr-md q-pl-md q-pt-sm q-pb-sm"
                :style="{ backgroundColor: cluster.color_background_icon }"
              >
                <q-img :src="cluster.icon" style="width: 20px; height: 20px" />
                <span
                  class="text-weight-bold q-ml-xs"
                  :style="{ color: cluster.text_color_icon }"
                >
                  {{ cluster.description }}
                </span>
              </q-badge>
              <q-space />
              <q-icon
                dense
                flat
                size="md"
                :color="cluster.is_block ? 'grey' : 'green'"
                :name="cluster.is_block ? 'lock' : 'lock_open'"
              />
            </q-bar>

            <p style="font-size: 11.5pt" class="q-mt-md">
              {{ cluster.short_explain }}
            </p>
            <q-linear-progress
              size="20px"
              color="grey"
              :value="cluster.percent"
              class="q-mt-md"
              style="border-radius: 10px"
            />
            <div class="text-right q-mt-xs">
              {{
                formatRange(
                  cluster.init_billing_range,
                  cluster.end_billing_range
                )
              }}
            </div>
          </q-card>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, defineProps } from "vue";

defineProps({
  rules: {
    type: String,
    default: "",
  },
});

const { useGetters } = createNamespacedHelpers("home");

const { getClusterPartnerInfo } = useGetters(["getClusterPartnerInfo"]);
const openedOrClosed = ref(false);

function formatToRealBR(value) {
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
}

const formatRange = (init, end) => {
  return `${formatToRealBR(init)} até ${formatToRealBR(end)}`;
};
</script>

<style scoped lang="scss">
.mobile-list {
  margin-left: -25px;
  text-align: justify;
}

.mobile-list li {
  margin-top: 5px;
}
.know-rules:hover {
  text-decoration: underline;
}
.know-rules-side {
  background-color: rgb(99, 99, 99);
}
.know-rules-side:hover {
  background-color: rgb(56, 56, 56);
  transition: 0.4s;
}
</style>
