<template>
  <DashAreaComponent title="Meus cupons" :titleBorder="false">
    <q-scroll-area style="height: 315px">
      <q-list bordered v-if="allCupons.length > 0">
        <q-item v-for="(cupon, i) in allCupons" :key="i">
          <q-item-section avatar>
            <q-icon name="local_activity" />
          </q-item-section>

          <q-item-section style="margin-left: -20px">
            <q-item-label lines="1">{{ cupon.label }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-icon
              name="content_copy"
              color="black"
              class="share-icon copy-btn"
              @click="copyLink(cupon.number.toUpperCase())"
            />
          </q-item-section>

          <q-item-section side>
            <ShareComponent :valueToShare="cupon.number.toUpperCase()" />
          </q-item-section>
        </q-item>
      </q-list>
      <div
        class="row text-center justify-center q-ma-lg q-mt-xl"
        v-if="allCupons.length == 0"
      >
        <voucher-icon :value="'--'" :type="''" />
        <p class="q-ma-lg full-width voucher-code">
          Não há cupons disponíveis.
        </p>
      </div>
    </q-scroll-area>
  </DashAreaComponent>
</template>

<script>
// import { copyLink } from "../../../../../shared/helpers/checkoutHelpers";
import { notifySuccess, notifyError } from "@/shared/helpers/notify";
import VoucherIcon from "@/modules/main/components/voucherIcon";
import DashAreaComponent from "./DashAreaComponent.vue";
import ShareComponent from "./ShareComponent.vue";

export default {
  name: "MyVouchersComponent",

  components: { DashAreaComponent, VoucherIcon, ShareComponent },

  props: {
    allCupons: Array,
  },

  setup() {
    function copyLink(link) {
      if (!link) {
        console.error("Nenhum voucher fornecido para cópia.");
        notifyError("Nenhum voucher fornecido para cópia.");
        return;
      }

      // Usando a API Clipboard para copiar o texto
      navigator.clipboard
        .writeText(link)
        .then(() => {
          notifySuccess("Voucher copiado com sucesso!");
        })
        .catch((err) => {
          console.error("Erro ao copiar o voucher:", err);
          notifyError("Erro ao copiar o voucher.");
        });
    }

    return { copyLink };
  },
};
</script>

<style scoped>
.share-icon {
  cursor: pointer;
}
.voucher-code {
  font-size: 1.2em;
  color: #5d5d5d;
}
</style>
